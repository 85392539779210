import 'slick-carousel/slick/slick.css'
import 'slick-carousel'

jQuery(document).ready(function ($) {
    var slider = $('.gz_b04_slider');

    if (slider.length > 0) {
        slider.slick({
            vertical: true,
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 1,
            arrows: false,
            autoplay: true,
            autoplaySpeed: 2500,
            verticalSwiping: true,
            speed: 1200,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 4,
                        verticalSwiping: false
                    }
                }
            ]
        });
    }
});
