//todo refactoring удалить неиспользуемый код, а используемый раскидать по отдельным файлам
jQuery(document).ready(function ($) {

  $('.dropdown-head').click(function (e) {
    if ($(this).next().is(":visible")) {
      $(this).next().hide('fast');
    }else
      $(this).next().show('fast');
  });
  $('.dropdown').on('click', function (e) {
    e.preventDefault();
    $('.dropdown_menu').hide('fast');
    if (!$(this).next('.dropdown_menu').is(':visible')) {
      $(this).next('.dropdown_menu').show('fast');
    }
  });
  $(document).mouseup(function (e) {
    var div = $(".dropdown_wrap");
    if (!div.is(e.target)
      && div.has(e.target).length === 0) {
      div.find('.dropdown_menu').hide('fast');
    }
  });
  $('.open-menu').on('click', function (e) {
    $('.menu-container').show('fast');

  });
  $('.menu-container>img').on('click', function () {
    $('.menu-container').hide('fast');
  });
  $('.popup-ul').on('click', function (e) {
    e.stopPropagation();
    const ul = $(this).find('ul:first');
    const $firstLi = $(this).find('li:first');
    if (ul.is(":visible")) {
      // $(this).find('li:first').css('background', 'white');
      $firstLi.removeClass('active');
      ul.hide('fast');
    } else {
      $firstLi.addClass('active');
      // $(this).find('li:first').css('background', '#18236B26');
      ul.show('fast');
    }


    function handle(){
      $firstLi.removeClass('active');
      ul.hide('fast');
      window.removeEventListener('click' , handle);
    }
    window.addEventListener('click', handle);
  });


  $('[name=js_change_select]').on('change', function () {
    if ($(this).val() == 2) {
      $('.js_change_show').show();
    } else {
      $('.js_change_show').hide();
    }
  });
  $(document).on('click', '.modal .js-close', function (e) {
    e.preventDefault();
    $.modal().close();
  });
  var file_api = (window.File && window.FileReader && window.FileList && window.Blob) ? true : false;

  $('.st_file_t').change(function () {
    var inp = $(this);
    var lbl = $(this).next('.st_file_field');
    var file_name;
    if (file_api && inp[0].files[0])
      file_name = inp[0].files[0].name;
    else
      file_name = inp.val().replace("C:\\fakepath\\", '');

    if (!file_name.length)
      return;
    lbl.html(file_name);
  }).change();

  // $('.js-vi_b05_tx9_lnk').on('click', function ()
  // {
  //   $('.lkz_b01').hide();
  //   $('.lkz_hd1_b01').show();
  // })
  $('.lk_b02_tx20_btn').on('click', function () {
    if (window.matchMedia("(max-width: 768px)").matches) {
      $(this).next('.lk_b02_tx20_hide').toggle('fast');
    }
  });
  $('body').on('input', '.js-count-sym', function () {
    var max = $(this).data('max');
    var count = $(this).val().length;
    var result = $(this).closest('.js-counter-wrap').find('.js-counter');
    if (count > max) {
      result.addClass('error');
    } else {
      result.removeClass('error');
    }
    result.html(count + '/' + max);
  });

  $(".flatpickr").flatpickr({
    allowInput: true,
    altFormat: "d.m.Y",
    altInput: true,
    locale: "ru",
    minDate: "today"
  });

  $('body').on('click', '.js-show-extra', function (e) {
    e.preventDefault();
    $('.js-extra-specs').slideDown('slow');
    $(this).hide();
    $('.js-hide-extra').show();
  });
  $('body').on('click', '.js-hide-extra', function (e) {
    e.preventDefault();
    $('.js-extra-specs').slideUp('slow');
    $(this).hide();
    $('.js-show-extra').show();
  });
  $(document).on('click', '.freelancers-catalog-link', function (e) {
    if (app.isUserLoggedIn) {
      return true;
    }

    // e.preventDefault();
  });
  const selectRoleModal=$('#select-role-modal');
  if (selectRoleModal.length) {
    selectRoleModal.modal().open();
    $('.themodal-overlay').off('click');
    $('#select-role').click(function () {
        window.location.href = '/select-role?role='+$('.select_role_radio:checked').attr('id');
    });
  }

  $(document).on('click', '.form-select-worktype__add', function (e) {
    for (var i = 1; i < 5; i++) {
      if (!$('.wt_select_' + i).hasClass('hidden') && $('.wt_select_' + (i + 1)).hasClass('hidden')) {
        $('.wt_select_' + (i + 1)).toggleClass('hidden');
        break;
      }
    }

    if (!$('.wt_select_5').hasClass('hidden')) {
      $('.form-select-worktype__add').addClass('hidden');
    }
  });
});
