const app = {};

//обёртка над $.ajax c настройками по умлочанию
app.ajax = function (options) {

  const $resultContainer = options.$resultContainer;
  const defaultOptions = {
    success: options.success || function (data, textStatus, request) {
      if (options.onSuccess) {
        options.onSuccess(data, textStatus, request);
      }
      if (options.afterSubmit) {
        options.afterSubmit();
      }
      var redirect = request.getResponseHeader('Location');
      var successMessage = data.successMessage;

      redirect && window.location.replace(redirect);
      successMessage && $resultContainer && $resultContainer.html(successMessage);
    },
    error: function (xhr, textStatus, text) {
      if (options.afterSubmit) {
        options.afterSubmit();
      }
      app.handleWarning(xhr.responseJSON?.warning);
      app.showAjaxError(xhr.responseJSON, $resultContainer);

    }
  };

  app.clearError($resultContainer);
  $.ajax(Object.assign(defaultOptions, options));
};

app.handleWarning = function (warningText) {
  if (warningText) {
    $('#warning-content').html(warningText);
    $('#warning_popup').modal().open();
  }
};

app.showAjaxError = function (errorData, $errorContainer) {
  let error = errorData && errorData.title || 'Произошла непредвиденная ошибка. Обратитесь в тех. поддержку.';
  app.showError(error, $errorContainer);
};

app.showError = function (error, $errorContainer) {
  $errorContainer && $errorContainer.html(`<span style="color: red;">${error}</span>`);
};

app.clearError = function ($errorContainer) {
  $errorContainer && $errorContainer.html('');
};

app.showFieldError = function (error, $field) {

  var $errorContainer = $field.siblings('.field-error-container');

  if (!$errorContainer.length) {
    $errorContainer = $field.parents().siblings('.field-error-container');
  }

  $errorContainer.html($(`<div class="field-error">${error}</div>`));
};

app.clearFieldErrors = function ($rootNode) {
  $rootNode.find('.field-error').remove();
};


app.setCookie = function(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

app.getCookie = function (name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

app.eraseCookie = function(name) {
  document.cookie = name + '=; Max-Age=-99999999;';
};

app.isUserLoggedIn = $('#js_app_data_holder').data('isUserLoggedIn');

export default app;
