import {isFreelancium} from "../../react-apps/constants/links";

jQuery(document).ready(function ($) {
    // Обработка событий клика по кнопкам, по которым должен открываться попап регистрации
    $(document).on('click', '.multistep-registration', function (e) {
        if (app.isUserLoggedIn) {
            return true;
        }

        e.preventDefault();
        app.ajax({
            url: '/multistep-registration',
            success: function (result) {
                var popup = $('#multistep_modal');

                if (popup.length === 0) {
                    $('body').append(result);
                } else {
                    popup.html($(result).html());
                }

                $('#multistep_modal').modal().open();
                $('#multistep_modal form').handleForm({
                    success: stepChangeSuccess
                });
            }
        });
    });

    // Обработка нажатия на кнопку "Назад"
    $(document).on('click', '#multistep_modal .prev-btn', function (e) {
        e.preventDefault();
        app.ajax({
            url: '/multistep-registration/prev',
            success: stepChangeSuccess
        });

        return true;
    });

    // Обработка изменения категории
    $(document).on('change', '#step1_category, [id^="step2_additionalSpecializations_"][id$="category"]', function () {
        const specName = !isFreelancium ? 'Предмет' : "Специализация";
        const specName2 = !isFreelancium ? 'Выберите предмет' : "Выберите специализацию";
        var categoryId = $(this).val(),
            specContainer = $(this).parent().next().find('select'),
            placeholderValue = $(this).attr('id') === 'step1_category' ? specName2 : specName,
            specPlaceholder = '<option value="" selected="selected">' + placeholderValue + '</option>';

        if (categoryId === '') {
            specContainer.html(specPlaceholder).attr('required', false);

            return true;
        }

        app.ajax({
            url: '/api/specializations-by-category/' + categoryId,
            success: function (result) {
                // Помещаем плейсхолдер в селект
                specContainer.html(specPlaceholder).attr('required', true);
                // И за ним полученные специализации
                result.forEach(function (row) {
                    specContainer.append('<option value="' + row.id + '">' + row.title + '</option>');
                });
            }
        });
    });

    // Обработчик ответа от бэка
    function stepChangeSuccess(result, textStatus, request) {
        var redirect = request.getResponseHeader('Location');

        if (redirect) {
            document.location.replace(redirect);
        } else {
            $("#multistep_modal").removeClass("tariff-modal-wrap");
            $('#multistep_modal').html($(result).html());
            $('#multistep_modal form').handleForm({
                success: stepChangeSuccess
            });
        }
    }
});
