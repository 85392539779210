export const tariffFR = process.env.PROJECT_ID === "fl" ? '/dopolnitelno-ispolnitelyam': '/dopolnitelno-expertam';
export const tariffCS = process.env.PROJECT_ID === "fl" ? '/dopolnitelno-ispolnitelyam': 'dopolnitelno-studentam';

export const projects = process.env.PROJECT_ID === "fl" ?  "Проекты" : "Задания";

export const isFreelancium =  process.env.PROJECT_ID === "fl";
export const PrName = isFreelancium ? "Freelancium" : "Students-go";

const freelanciumData = {
    customerNotify: 'https://t.me/freelancium_project_bot',
    freelancerNotify: 'https://t.me/freelancium_bot',
    freelancerTariff: '/dopolnitelno-ispolnitelyam',
    customerTariff: '/dopolnitelno-zakazchikam',
    freelancerNotifyFull: 'tg://resolve?domain=freelancium_bot&start=c1683707054606-ds',
    customerNotifyFull: 'tg://resolve?domain=freelancium_project_bot&start=c1685693179542-ds'
};
const studentsData = {
    customerNotify: 'https://t.me/Student_GoBot',
    freelancerNotify: 'https://t.me/Student_Go_ProjectBot',
    freelancerTariff: '/dopolnitelno-expertam',
    customerTariff: '/dopolnitelno-studentam',
    freelancerNotifyFull: 'tg://resolve?domain=Student_Go_ProjectBot&start=c1695721336984-ds',
    customerNotifyFull: 'tg://resolve?domain=Student_GoBot&start=c1695717301795-ds'
};

export const platformData = isFreelancium ? freelanciumData : studentsData;