(function ($) {
  $.fn.handleForm = function (options) {
    return this.each(function () {
      var $form = $(this);
      var $resultContainer = options && options.$resultContainer;

      options = options || {};
      if (!$resultContainer && $form.find('.js-result').length) {
        $resultContainer = $form.find('.js-result');
      }

      $form.submit(function (e) {
        e.preventDefault();

        if (options.beforeSubmit) {
          options.beforeSubmit();
        }
        var submit = $form.find(':submit');
        submit.prop('disabled', true);

        let defaultOptions = {
          $resultContainer: $resultContainer,
          url: options.url || $form.attr('action'),
          type: options.type || $form.attr('method'),
          data: new FormData($form[0]),
          contentType: false,
          processData: false,
          error: function (xhr, textStatus, text) {

            var errorData = xhr.responseJSON;
            var errors = errorData && errorData.errors;
            if (options.afterSubmit) {
              options.afterSubmit();
            }
            app.handleWarning(errorData.warning);

            if (xhr.status === 400 && errors) {
              mapErrorsToForm(errors);
              return;
            }

            app.showAjaxError(errorData, $resultContainer);
            submit.prop('disabled', false);

          },
          complete: function () {
            submit.prop('disabled', false);
          }
        };
        clearErrors();
        app.ajax(Object.assign(defaultOptions, options));

      });

      function mapErrorsToForm(errors) {
        var $field, selector;
        var formName = $form.attr('name');

        function getFieldChainAndValue(fieldName, errors) {
          var errorMessage = errors[fieldName][0];
          if (errorMessage) {
            return {
              keys: [fieldName],
              value: errorMessage
            };
          } else {
            return {
              keys: [fieldName].concat(getFieldChainAndValue(Object.keys(errors[fieldName])[0], errors[fieldName]).keys),
              value: getFieldChainAndValue(Object.keys(errors[fieldName])[0], errors[fieldName]).value
            };
          }

        }

        for (var fieldName in errors) {
          var res = getFieldChainAndValue(fieldName, errors);

          if (formName) {
            selector = formName;
          } else {
            selector = res.keys.shift();
          }

          if (res.keys.length) {
            selector = `${selector}[${res.keys.join('][')}]`;
          }

          $field = $form.find(`[name="${selector}"]`);

          if ($field.length > 0) {
            app.showFieldError(res.value, $field);
          }
        }

        if (errors[0] !== undefined) {
          app.showError(errors[0], $resultContainer);
        }
      }

      function clearErrors() {
        app.clearFieldErrors($form);
        app.clearError($resultContainer);
      }
    });
  };
}(jQuery));