import '../styles/index.scss';

import $ from 'jquery';
global.$ = global.jQuery = $;

import '../js/modal';
//todo use same sources for React app and jquery flatpickr plugin (from package)
import '../flatpickr';

import app from '../js/app';
global.app = app;
window.isFreelancium =  process.env.PROJECT_ID === "fl";
import '../js/jquery.app.handle-form';

import '../js/script';
import '../multistep-registration';
import '../js/slider';
import '../landing/landing';
