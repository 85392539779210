import 'slick-carousel';

const burger = document.querySelector('[data-element="header-land__burger"]');

if (burger) setTimeout(burgerInit, 0);

function burgerInit() {
    burger.addEventListener('click', toggleMenu);
    const headerLangList = document.querySelector('[data-element="header-land__list"]');

    function toggleMenu() {
        if (burger.classList.contains('header-land__burger_active')) {
            burger.classList.remove('header-land__burger_active');
            headerLangList.classList.remove('header-land__list_active');
        } else {
            burger.classList.add('header-land__burger_active');
            headerLangList.classList.add('header-land__list_active');
        }
    }
}

const view = document.querySelector('[data-element="faq"]');

if (view) setTimeout(faqInit, 0);

function faqInit() {
    const questions = view.querySelectorAll('[data-element="faq__head"]');
    questions.forEach(element =>  {
        element.addEventListener('click', toggleQuestion);
    });
}

function toggleQuestion (event) {
    const headBlock = event.currentTarget;
    headBlock.classList.toggle('faq__head_active');
}

const button = document.querySelector('[data-element="smart-button"]');
const afterView = document.querySelector('[data-role="smart-button__after-view"]');

if (button && afterView) setTimeout(initSmartButton, 0);

function initSmartButton () {
    window.addEventListener('scroll', checkVisibleButton, { passive: true });
}

function checkVisibleButton () {
    if (afterView.getBoundingClientRect().top + afterView.clientHeight < 0) {
        button.classList.add('smart-button_active');
    } else {
        button.classList.remove('smart-button_active');
    }
}

jQuery(document).ready(function ($) {
    $('.feedback__slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        infinite: true,
        variableWidth: true,
        rows: 0
    });
});
